
























































































import { Component, Vue } from 'vue-property-decorator'
import { RequestPaging } from '@/utils/util'
import LsPagination from '@/components/ls-pagination.vue'
import {
    apiLiveRoomLists,
    apiLiveRoomDel
} from '@/api/application/live_broadcast'
import { PageMode } from '@/utils/type'
import LsDialog from '@/components/ls-dialog.vue'
import config from '@/config'
@Component({
    components: {
        LsPagination,
        LsDialog
    }
})
export default class LiveBroadcast extends Vue {
    srcList = [
        'https://fuss10.elemecdn.com/8/27/f01c15bb73e1ef3793e64e6b7bbccjpeg.jpeg'
    ]
    weChatZhiBoImg = require('@/assets/images/wechatzhibo.png')

    // 分页查询
    pager: RequestPaging = new RequestPaging()

    // 创建直播间
    onAdd() {
        this.$router.push({
            path: '/live_broadcast/edit',
            query: {
                mode: PageMode.ADD
            }
        })
    }

    // 删除
    onDelete(value: any) {
        apiLiveRoomDel({
            room_id: value.room_id
        })
            .then(() => {
                this.getList()
                // this.$message.success("删除成功!");
            })
            .catch(() => {
                //this.$message.error("删除失败")
            })
    }

    onReset() {
        // this.$set(this.pager, 'page', 1)
        this.getList()
    }

    // 获取列表
    getList() {
        // 分页请求
        this.pager
            .request({
                callback: apiLiveRoomLists
            })
            .catch(() => {
                this.$message.error('数据请求失败，刷新重载')
            })
    }

    created() {
        this.getList()
    }
}
